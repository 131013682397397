.invisible-lines-table tr,
.invisible-lines-table td {
    border-color: transparent;
}
.file-name {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mime-type {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.action-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}
