.crop-container {
    position: relative;
    margin: 30px auto;
    width: 700px;
    height: 350px;
    background-color: black;
}

.croppedImage {
    width: 600px;
    height: 337px;
    cursor: pointer;
    transition: filter 0.3s ease;
    filter: blur(4px) brightness(50%);
    object-fit: cover;
    border-radius: 0.25rem;
}

.croppedImage:hover {
    filter: brightness(70%);
}

.saveButtonDiv {
    align-self: center;
    margin-bottom: 30px;
}

.saveButton {
    width: 150px;
}

.filePicker {
    display: none;
}

.filePickerLabel {
    border: 3px solid;
    border-radius: 16px;
    padding: 0.4em 16px;
    border-color: #0d6efd;
}

.filePickerLabel:hover {
    cursor: pointer;
    border-color: #0d6efd;
    color: white;
    background-color: #0d6efd;
}

.fileDiv {
    text-align: center;
    width: 600px;
    height: 337px;
}

.reactEasyCrop_CropArea {
    border: 2px solid !important;
    border-color: #0d6efd !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.reactEasyCrop_CropArea::before {
    border: 2px solid !important;
    border-color: #0d6efd !important;
}

.reactEasyCrop_CropArea::after {
    border: 2px solid !important;
    border-color: #0d6efd !important;
}
.image-info-overlay {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    pointer-events: none;
    text-align: center;
    background-color: transparent;
    z-index: 2;
}

.image-present-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    width: 600px;
    height: 337px;
}
.image-absent-overlay {
    background-color: transparent;
    width: 600px;
    height: 337px;
}
.croppedImage {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}
.upload-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}
.image-crop-container {
    flex: 1;
    position: relative;
    margin-left: 20px;
    border-radius: 0.25rem;
    overflow: hidden;
}
.delete-cover-image-btn {
    position: absolute;
    top: 7px;
    right: 35px;
}
