blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    color: #e0e0e0;
    background-color: #333333;
    border-left: 4px solid #cccccc;
    padding: 0.5em 20px;
    font-style: italic;
}
