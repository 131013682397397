@import "~bootstrap/scss/bootstrap.scss";

.poc-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_image-wrapper {
        position: relative;
        overflow: hidden;

        &_img {
            width: 100%;
            height: 200px;
        }

        &_banner {
            top: 15px;
            left: -30px;
            position: absolute;
            background-color: #9e0101;
            width: 120px;
            height: 30px;
            transform: rotate(-45deg);
            box-shadow: 0px 0px 7px 1px #000000;

            &_text {
                font-size: 15px;
                left: 35px;
                position: absolute;
            }
        }
    }

    &_body {
        background-color: #383b45;
    }
}

.card-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card-description {
    margin-top: 10px;
    height: 200px !important;
}

.card-authors {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    height: 3em;
}
.card-tags {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    height: 1.5em;
}
.poc-card_body_text {
    height: 110px;
}
.card-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    min-height: 80px;
}

.card-footer p {
    margin-bottom: 0;
}

.card-footer-buttons {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.label-bold {
    font-weight: bold;
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}
