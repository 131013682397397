.name-filter-input {
    width: 100%;
}

.filters-container {
    margin-bottom: 1em;
}

.filters-author-dropdown,
.filters-tag-dropdown {
    width: 100%;
}

.dropdown-menu {
    width: 100%;
}

.dropdown-toggle {
    width: 100%;
    text-align: left;
}

.col-lg-1,
.col-lg-3 {
    padding-right: 5px;
    padding-left: 5px;
}
.published-filter {
    margin-right: 220px;
}
