.language-picker {
    &--dropdown {
        text-align: right;

        button {
            background-color: transparent;
            opacity: 50%;

            &:hover {
                background-color: transparent;
                opacity: 100%;
            }
        }

        .dropdown-menu {
            width: auto;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }
}
