.pocs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}
.sorting-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.sorting-controls select,
.sorting-controls button {
    margin-left: 5px;
    min-width: 50px;
}
.search-input-group {
    align-items: right;
}
.search-row {
    margin-bottom: 20px;
}

.col-lg-1,
.col-lg-3 {
    padding-right: 5px;
    padding-left: 5px;
}
