.tags-container {
    display: flex;
    align-items: start;
    gap: 10px;
    flex-wrap: wrap;
    word-break: break-all;
}
.authors-container {
    display: flex;
    align-items: start;
    gap: 10px;
    flex-wrap: wrap;
}
.poc-cover-wrapper {
    position: relative;
    overflow: hidden;
}

.poc-draft-label {
    top: 100px;
    left: -50px;
    position: absolute;
    background-color: #9e0101;
    width: 210px;
    height: 40px;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    box-shadow: 0px 0px 7px 1px #000000;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1em;
}
.poc-description {
    white-space: pre-line;
}
.poc-title {
    word-break: break-all;
}
