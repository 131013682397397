@import "~bootstrap/scss/bootstrap";

.create-poc-form {
    .form-button-group {
        display: flex;
        justify-content: flex-end;
    }
    .submit-button {
        float: right;
    }
}
