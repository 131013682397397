@import "~bootstrap/scss/bootstrap";

.addListItem {
    border-radius: 0.5rem !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.addListItem:hover {
    cursor: pointer;
    background-color: lightgreen;
    color: black;
}

.removeListItem {
    border-radius: 0.5rem !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.removeListItem:hover {
    cursor: pointer;
    background-color: rgb(192, 28, 61);
    color: black;
}

.itemsAddedLabel {
    margin-top: 2rem;
}

.authorsSearch {
    margin-bottom: 1rem;
}

.tagsCreate {
    margin-bottom: 1rem;
}

.addList {
    display: flex;
    flex-wrap: wrap;
}

.removeList {
    display: flex;
    flex-wrap: wrap;
}

.listContainer {
    width: 100%;
}
