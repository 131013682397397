@import "~bootstrap/scss/bootstrap";

.edit-poc-form {
    .form-button-group {
        display: flex;
        justify-content: flex-end;
    }
}

.edit-poc-form .mdx-editor__textarea,
.edit-poc-form .mdx-editor__textarea::placeholder {
    color: white !important;
}
.error-message {
    color: red;
    margin-top: 10px;
}
._editorRoot_utjas_37 {
    --basePageBg: #212529 !important;
}
.content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.form-container {
    flex: 1;
    padding-left: 20px;
}

.image-container {
    flex-basis: 300px;
    margin-top: 20px;
}
h2 {
    word-break: break-all;
}
